import { forOwn, find } from "lodash";

export const CATEGORIES = [
  {
    id: "wohnbau",
    title: "Wohnbau",
  },
  {
    id: "gewerbebauGaragen",
    title: "Gewerbebau | Garagen",
  },
  {
    id: "umbauAdaptierung",
    title: "Umbau | Adaptierung",
  },
  {
    id: "hotelHeim",
    title: "HOTEL | HEIM",
  },
];

export const getDetails = ({ project }) => {
  let details = {};
  let categories = [];
  let location = {};

  const wrapper = {
    details,
    categories,
    location,
  };

  const DETAILS = {
    betreutesWohnen: "categories",
    gewerbebauGaragen: "categories",
    umbauAdaptierung: "categories",
    wohnbau: "categories",
    hotelHeim: "categories",
    projectStatus: "details",
    projectPartners: "details",
    location: "location",
    leistungen: "details",
    clientName: "details",
    jahr: "details",
    zeitraum: "details",
    leistungszeitraum: "details",
  };

  forOwn(project, (value, key) => {
    const isDetail = DETAILS[key];
    if (isDetail) {
      if (isDetail === "categories") {
        if (value === true) {
          const o = find(CATEGORIES, { id: key });
          wrapper.categories.push(o);
        }
      } else if (isDetail === "location") {
        wrapper.location = value;
      } else {
        wrapper.details[key] = value;
      }
    }
  });

  // forEach(details, detail => {
  //   if (detail && detail.__typename === "DatoCmsCategory") {
  //     forOwn(detail, (val, key) => {
  //       if (find(CATEGORIES, { id: key }) && val === true) {
  //         categories.push(find(CATEGORIES, { id: key }));
  //       }
  //     });
  //   }
  //   if (detail && detail.__typename === "DatoCmsClient") {
  //     client.clientName = detail.clientName;
  //   }
  //   if (detail && detail.__typename === "DatoCmsLocation") {
  //     location.geolocation = detail.geolocation;
  //     location.address = detail.address;
  //   }
  // });

  return wrapper;
};

export const random = (min, max) => {
  if (max == null) {
    max = min;
    min = 0;
  }
  if (min > max) {
    var tmp = min;
    min = max;
    max = tmp;
  }
  return min + (max - min) * Math.random();
};

export const cardinal = (data, closed, tension) => {
  // Cardinal spline - a uniform Catmull-Rom spline with a tension option
  if (data.length < 1) return "M0 0";
  if (tension == null) tension = 1;

  var size = data.length - (closed ? 0 : 1);
  var path = "M" + data[0].x + " " + data[0].y + " C";

  for (var i = 0; i < size; i++) {
    var p0, p1, p2, p3;

    if (closed) {
      p0 = data[(i - 1 + size) % size];
      p1 = data[i];
      p2 = data[(i + 1) % size];
      p3 = data[(i + 2) % size];
    } else {
      p0 = i === 0 ? data[0] : data[i - 1];
      p1 = data[i];
      p2 = data[i + 1];
      p3 = i === size - 1 ? p2 : data[i + 2];
    }

    var x1 = p1.x + ((p2.x - p0.x) / 6) * tension;
    var y1 = p1.y + ((p2.y - p0.y) / 6) * tension;

    var x2 = p2.x - ((p3.x - p1.x) / 6) * tension;
    var y2 = p2.y - ((p3.y - p1.y) / 6) * tension;

    path += " " + x1 + " " + y1 + " " + x2 + " " + y2 + " " + p2.x + " " + p2.y;
  }

  return closed ? path + "z" : path;
};
