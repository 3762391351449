import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { Div, Section } from "./Markup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconPng } from "@components/Icon";

const GALLERY_HEIGHT = 80;

const PrevArrow = props => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      css={css`
        padding: 12px;
        background: white;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 20;
        transform: translate3d(0, -50%, 0);
        cursor: pointer;

        &:hover {
          background: #e9e9e9;
        }
        img {
          float: left;
        }
      `}
    >
      <IconPng name={"arrowLeft"} />
    </div>
  );
};

const NextArrow = props => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      css={css`
        padding: 12px;
        background: white;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 20;
        cursor: pointer;

        &:hover {
          background: #e9e9e9;
        }
        transform: translate3d(0, -50%, 0);
        img {
          float: left;
        }
      `}
    >
      <IconPng name={"arrowRight"} />
    </div>
  );
};

const defaultSettings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchThreshold: 13,
  centerPadding: 0,
  autoplay: true,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
};

const GalleryItem = ({ fluid }) => {
  return (
    <Div
      css={css`
        height: ${GALLERY_HEIGHT}vh;
        width: 100%;
        overflow: hidden;
        position: relative;
      `}
    >
      <Div
        css={css`
          height: ${GALLERY_HEIGHT}vh;
          width: ${fluid.aspectRatio * GALLERY_HEIGHT}vh;
          margin: 0 auto;
          position: relative;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);

          img {
            max-height: 100%;
            height: 100%;
          }
        `}
        className="zas-section__item"
      >
        <img src={fluid.src} srcSet={fluid.srcSet} />
      </Div>
    </Div>
  );
};

const Gallery = ({ items }) => {
  return (
    <Section
      pt="sm"
      pb="sm"
      css={css`
        position: relative;
      `}
    >
      <Slider
        {...defaultSettings}
        css={css`
          .slick-dots {
          }

          .slick-track {
            display: flex;
          }

          .slick-slide {
            opacity: 1;
            transition: opacity 0.1s;
            outline: none !important;
          }

          .slick-next {
            right: 10px;
          }

          .slick-prev {
            left: 10px;
            z-index: 20;
          }
        `}
      >
        {items.map((item, index) => (
          <GalleryItem key={`gallery-item--${index}`} {...item} />
        ))}
      </Slider>
    </Section>
  );
};

/* <Cursor
        containerClass={"zas-section"}
        clickableClass={"zas-section__item"}
      /> */

Gallery.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
        srcSet: PropTypes.string,
      }),
    })
  ),
};

Gallery.defaultProps = {
  items: [
    {
      fluid: {
        src:
          "https://www.datocms-assets.com/39001/1614069865-df164-01-1.jpg?auto=format",
        srcSet:
          "https://www.datocms-assets.com/39001/1614069865-df164-01-1.jpg?auto=format&dpr=0.16&w=1280 200w,\nhttps://www.datocms-assets.com/39001/1614069865-df164-01-1.jpg?auto=format&dpr=0.32&w=1280 400w,\nhttps://www.datocms-assets.com/39001/1614069865-df164-01-1.jpg?auto=format&dpr=0.63&w=1280 800w,\nhttps://www.datocms-assets.com/39001/1614069865-df164-01-1.jpg?auto=format&dpr=0.94&w=1280 1200w,\nhttps://www.datocms-assets.com/39001/1614069865-df164-01-1.jpg?auto=format&dpr=1&w=1280 1280w",
        aspectRatio: 1.5058823529411764,
      },
    },
    {
      fluid: {
        src:
          "https://www.datocms-assets.com/39001/1614069873-df164-02-1.jpg?auto=format",
        srcSet:
          "https://www.datocms-assets.com/39001/1614069873-df164-02-1.jpg?auto=format&dpr=0.16&w=1280 200w,\nhttps://www.datocms-assets.com/39001/1614069873-df164-02-1.jpg?auto=format&dpr=0.32&w=1280 400w,\nhttps://www.datocms-assets.com/39001/1614069873-df164-02-1.jpg?auto=format&dpr=0.63&w=1280 800w,\nhttps://www.datocms-assets.com/39001/1614069873-df164-02-1.jpg?auto=format&dpr=0.94&w=1280 1200w,\nhttps://www.datocms-assets.com/39001/1614069873-df164-02-1.jpg?auto=format&dpr=1&w=1280 1280w",
        aspectRatio: 1.5058823529411764,
      },
    },
    {
      fluid: {
        src:
          "https://www.datocms-assets.com/39001/1614069876-df164-03-1.jpg?auto=format",
        srcSet:
          "https://www.datocms-assets.com/39001/1614069876-df164-03-1.jpg?auto=format&dpr=0.16&w=1280 200w,\nhttps://www.datocms-assets.com/39001/1614069876-df164-03-1.jpg?auto=format&dpr=0.32&w=1280 400w,\nhttps://www.datocms-assets.com/39001/1614069876-df164-03-1.jpg?auto=format&dpr=0.63&w=1280 800w,\nhttps://www.datocms-assets.com/39001/1614069876-df164-03-1.jpg?auto=format&dpr=0.94&w=1280 1200w,\nhttps://www.datocms-assets.com/39001/1614069876-df164-03-1.jpg?auto=format&dpr=1&w=1280 1280w",
        aspectRatio: 1.5058823529411764,
      },
    },
    {
      fluid: {
        src:
          "https://www.datocms-assets.com/39001/1614069880-df164-04-1.jpg?auto=format",
        srcSet:
          "https://www.datocms-assets.com/39001/1614069880-df164-04-1.jpg?auto=format&dpr=0.16&w=1280 200w,\nhttps://www.datocms-assets.com/39001/1614069880-df164-04-1.jpg?auto=format&dpr=0.32&w=1280 400w,\nhttps://www.datocms-assets.com/39001/1614069880-df164-04-1.jpg?auto=format&dpr=0.63&w=1280 800w,\nhttps://www.datocms-assets.com/39001/1614069880-df164-04-1.jpg?auto=format&dpr=0.94&w=1280 1200w,\nhttps://www.datocms-assets.com/39001/1614069880-df164-04-1.jpg?auto=format&dpr=1&w=1280 1280w",
        aspectRatio: 1.5058823529411764,
      },
    },
    {
      fluid: {
        src:
          "https://www.datocms-assets.com/39001/1614069883-df164-05-1.jpg?auto=format",
        srcSet:
          "https://www.datocms-assets.com/39001/1614069883-df164-05-1.jpg?auto=format&dpr=0.16&w=1280 200w,\nhttps://www.datocms-assets.com/39001/1614069883-df164-05-1.jpg?auto=format&dpr=0.32&w=1280 400w,\nhttps://www.datocms-assets.com/39001/1614069883-df164-05-1.jpg?auto=format&dpr=0.63&w=1280 800w,\nhttps://www.datocms-assets.com/39001/1614069883-df164-05-1.jpg?auto=format&dpr=0.94&w=1280 1200w,\nhttps://www.datocms-assets.com/39001/1614069883-df164-05-1.jpg?auto=format&dpr=1&w=1280 1280w",
        aspectRatio: 1.5058823529411764,
      },
    },
    {
      fluid: {
        src:
          "https://www.datocms-assets.com/39001/1614069887-df164-06-1.jpg?auto=format",
        srcSet:
          "https://www.datocms-assets.com/39001/1614069887-df164-06-1.jpg?auto=format&dpr=0.16&w=1280 200w,\nhttps://www.datocms-assets.com/39001/1614069887-df164-06-1.jpg?auto=format&dpr=0.32&w=1280 400w,\nhttps://www.datocms-assets.com/39001/1614069887-df164-06-1.jpg?auto=format&dpr=0.63&w=1280 800w,\nhttps://www.datocms-assets.com/39001/1614069887-df164-06-1.jpg?auto=format&dpr=0.94&w=1280 1200w,\nhttps://www.datocms-assets.com/39001/1614069887-df164-06-1.jpg?auto=format&dpr=1&w=1280 1280w",
        aspectRatio: 1.5058823529411764,
      },
    },
  ],
};

export default Gallery;
