import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import styled from "styled-components";
import { Title, TEXT_STYLES } from "@components/Typography";
import { split } from "lodash";
import { Div } from "@components/Markup";
import ScrollIndicator from "./ScrollIndicator";

const thumbnailCss = `display: block;
        transition: 0.15s all;
        position: relative;
        width: 100%;
        height: calc(100vh - 128px);
        `;

const CategoryLink = styled(Link)`
  ${TEXT_STYLES.label}
  position: relative;

  &:before {
    position: absolute;
    width: 100%;
    height: 1px;
    background: white;
    top: 100%;
    left: 0;
    pointer-events: none;
    content: "";
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
  }

  &:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
  }
`;

const Hero = ({ featuredImage, name, featuredVideoId, categories }) => {
  const splitName = split(name, " | ");
  const displayName = splitName[0] + " | " + splitName[1];
  const displayLocation = splitName[2];
  return (
    <Div
      mb="lg"
      css={css`
        ${thumbnailCss}
      `}
    >
      <div
        css={css`
          background-image: url("${featuredImage && featuredImage.fluid.src}");
          background-size: cover;
          background-position: center center;
          height: 100%;
          width: 100%;
        `}
      ></div>
      <Div
        flex
        jc="flex-end"
        ai="flex-start"
        pl="sm"
        pb="md"
        css={css`
          flex-direction: column;
          background: rgb(51, 51, 51);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 51%,
            rgba(255, 255, 255, 0) 100%
          );
          position: absolute;
          top: 0;
          left: 0;
          color: white;
          height: 100%;
          width: 100%;
        `}
      >
        <Div
          flex
          jc="space-between"
          ai="flex-end"
          css={css`
            width: calc(100% - 20px);
            @media (min-width: 768px) {
              width: calc(100% - 50px);
            }
          `}
        >
          <Div
            css={css`
              max-width: 90%;
            `}
          >
            <Div mb="xs">
              {categories.map(category => (
                <CategoryLink
                  key={category && category.id}
                  to={`/projekte?category=${category && category.id}`}
                >
                  {category && category.title}
                </CategoryLink>
              ))}
            </Div>
            <Title
              size="xl"
              tag="h1"
              uppercase
              mb="xs"
              css={css`
                @media (max-width: 767px) {
                  font-size: 36px !important;
                }
              `}
            >
              {displayName}
            </Title>
            <Title size="md" tag="h3">
              {displayLocation}
            </Title>
          </Div>
          <ScrollIndicator />
        </Div>
      </Div>
    </Div>
  );
};

export default Hero;
