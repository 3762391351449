import React, { useEffect, useRef, useContext } from "react";
import { Div } from "@components/Markup";
import { css } from "@emotion/react";
import Player from "@vimeo/player";
import { ResizeContext } from "@providers/ResizeProvider";
import Spinner from "@components/Spinner";
import gsap from "gsap";

const Video = ({ id }) => {
  //let pid = "579757037";
  const videoContainerRef = useRef(null);
  const playerRef = useRef(null);
  const loadingRef = useRef(null);
  const { addResizeCallback } = useContext(ResizeContext);

  const initVideo = () => {
    if (playerRef.current) {
      playerRef.current.destroy();

      gsap.to(loadingRef.current, {
        opacity: 1,
        duration: 0.15,
      });
    }
    if (videoContainerRef.current) {
      playerRef.current = new Player(videoContainerRef.current, {
        id: id,
        title: true,
        controls: true,
        byline: true,
        background: false,
        autoplay: true,
        muted: true,
        loop: true,
        width: window.innerWidth,
        height: 0.56 * window.innerWidth,
      });

      playerRef.current.on("play", function () {
        gsap.to(loadingRef.current, {
          opacity: 0,
          duration: 0.15,
        });
      });
    }
  };

  useEffect(() => {
    initVideo();
  }, []);

  // useEffect(() => {
  //   addResizeCallback(initVideo);
  // }, []);

  return (
    <Div
      mb="sm"
      mt="sm"
      css={css`
        padding-top: 56%;
        position: relative;
        width: 100%;
        iframe {
          max-width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      `}
    >
      <div ref={videoContainerRef}></div>
      <div
        ref={loadingRef}
        css={css`
          position: absolute;
          pointer-events: none;
          height: 100%;
          width: 100%;
          color: white;
          text-transform: uppercase;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Spinner />
      </div>
    </Div>
  );
};

export default Video;
